const CONFIG = {
  title: 'bina.az',
  gtmContainerId: 'GTM-54QWK2',
  fbAppId: '114513531950627',
  asset_host: 'bina.azstatic.com',
  fallback_token: 'BINAAZ',
  protocol: process.env.BINAAZ_PROTOCOL || 'https',
  host: process.env.BINAAZ_HOST || 'binaaz-graphql.azstaging.net',
  languages: ['az', 'ru'],
  debugMode: false,
  contacts: {
    tel: '(012) 505-08-02',
    tel_url: 'tel:+994125050802',
    tel_additional: '(012) 505-08-02',
    tel_additional_url: 'tel:+994125050802',
    email: 'bina@bina.az',
    instagramLink: 'https://www.instagram.com/bina.az.official/',
    facebookLink: 'https://www.facebook.com/www.bina.az'
  },
  socialNetworksArr: ['Facebook', 'Instagram', 'Youtube'],
  currentYear: new Date().getFullYear(),
  googleApiKey: 'AIzaSyDZdErhaS00KX2GvHGt5-wBIWjcohJaxdM',
  phoneOperatorFormat: '0(10|50|51|55|60|70|77|99)'
};

export default CONFIG;
